import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import GitLabApi from '../backend/GitlabApi';
import formOptions from './formOptions.json';
import InfoTooltip from '../components/InfoTooltip';
import countryList from 'react-select-country-list';

function PaidUsers() {
    const initialProject = formOptions.projects[0];
    const [project, setProject] = useState(initialProject.name);
    const [server, setServer] = useState(formOptions.servers[0]);
    const [userWeight, setUserWeight] = useState(formOptions.userWeight);
    const [userHeight, setUserHeight] = useState(formOptions.userHeight);
    const [userAge, setUserAge] = useState(formOptions.userAge);
    const [fitLvl, setFitLvl] = useState(formOptions.fitlevels[0]);
    const [userWeightTarget, setUserWeightTarget] = useState(formOptions.userWeightTarget);
    const [gender, setGender] = useState(initialProject.genders[0]);
    const [branchId, setBranch] = useState('default');
    const [upsellProductCode, setUpsellProductCode] = useState('');
    const [bmi, setBmi] = useState(null);
    const [problemZones, setProblemZones] = useState([]);
    const [equipmentPreset, setEquipmentPreset] = useState('');
    const [trainingLocations, setTrainingLocations] = useState([]);
    const [goal, setGoal] = useState(initialProject.goals ? initialProject.goals[0] : '');
    const [consentEmail, setConsentEmail] = useState(true);
    const [country, setCountry] = useState({value: 'US', label: 'United States'});
    const [flashMessage, setFlashMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(false);
    const [showFlash, setShowFlash] = useState(false);
    const [webUrl, setWebUrl] = useState('');

    const gitLabApi = new GitLabApi();
    const countries = countryList().getData();

    const handleFlashMessageClick = () => {
        if (webUrl) {
            window.open(webUrl, '_blank');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedProject = formOptions.projects.find(p => p.name === project);
        const gitlabProjectId = selectedProject.gitlabId;
        console.log(`Project: ${project}, Server: ${server}, Branch: ${branchId}, User Weight: ${userWeight}, User Height: ${userHeight}, GitLab Project ID: ${gitlabProjectId}`);

        try {
            const variables = {
                project,
                server,
                user_height: userHeight,
                user_weight: userWeight,
                user_target_weight: userWeightTarget,
                user_age: userAge,
                fitness_lvl: fitLvl,
                gender,
                problem_zones: problemZones.map(zone => zone.value).join(','),
                equipment_preset: equipmentPreset,
                training_locations: trainingLocations.map(location => location.value).join(','),
                goal: project === 'Menscoach' || 'MuscleBoosterOld' ? goal : goal.map(g => g.value).join(','),
                consent_email: consentEmail,
                country: country ? country.value : ''
            };

            if (branchId) {
                variables.BRANCH_NAME = branchId;
            }

            if (upsellProductCode) {
                variables.UPSELL = upsellProductCode;
            }



            const response = await gitLabApi.triggerPipeline({
                gitlabProjectId,
                ref: 'master',
                variables
            });
            console.log(response)
            if (response.status === 201) {
                setFlashMessage('Pipeline triggered successfully.');
                setIsPositiveMessage(true);
                setWebUrl(response.data.web_url);
            } else {
                setFlashMessage('Failed to trigger pipeline.');
                setIsPositiveMessage(false);
            }
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 10000);
        } catch (error) {
            console.error('Failed to trigger pipeline:', error);
            setFlashMessage('Error triggering pipeline.');
            setIsPositiveMessage(false);
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 5000);
        }
    };

    const calculateBmi = () => {
        if (userWeight && userHeight) {
            const heightInMeters = userHeight / 100;
            const bmiValue = (userWeight / (heightInMeters * heightInMeters)).toFixed(2);
            setBmi(bmiValue);
        } else {
            setBmi(null);
        }
    };

    useEffect(() => {
        calculateBmi();
        //eslint-disable-next-line
    }, [userWeight, userHeight]);

    useEffect(() => {
        const selectedProject = formOptions.projects.find(p => p.name === project);
        setGender(selectedProject.genders[0]);
        setProblemZones([]);
        setTrainingLocations([]);
        setEquipmentPreset('');
        setGoal(selectedProject.goals ? selectedProject.goals[0] : '');
    }, [project]);

    const selectedProject = formOptions.projects.find(p => p.name === project);
    const problemZoneOptions = selectedProject.problem_zones.map(zone => ({value: zone, label: zone}));
    const trainingLocationsOptions = selectedProject.training_locations
        ? selectedProject.training_locations.map(location => ({
            value: location,
            label: location
        }))
        : [];
    const equipmentPresetOptions = selectedProject.equipment_preset.map(preset => ({value: preset, label: preset}));
    const genderOptions = selectedProject.genders.map(g => ({value: g, label: g}));
    const goalOptions = selectedProject.goals ? selectedProject.goals.map(g => ({value: g, label: g})) : [];

    return (
        <div className="container">
            <h1 className="title">Paid Users Creation</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Project
                    <div className="input-group">
                        <select value={project} onChange={e => setProject(e.target.value)} className="select-field">
                            {formOptions.projects.map(p => (
                                <option key={p.name} value={p.name}>{p.name}</option>
                            ))}
                        </select>
                    </div>
                </label>
                <label>
                    Server
                    <div className="input-group">
                        <select value={server} onChange={e => setServer(e.target.value)} className="select-field">
                            {formOptions.servers.map(s => (
                                <option key={s} value={s}>{s}</option>
                            ))}
                        </select>
                    </div>
                </label>
                <label>
                    User Gender <InfoTooltip message={`Default value: ${gender}`}/>
                    <div className="input-group">
                        <Select
                            value={{value: gender, label: gender}}
                            onChange={option => setGender(option.value)}
                            options={genderOptions}
                            className="select-field"
                        />
                    </div>
                </label>
                {goalOptions.length > 0 && (
                    <label>
                        User Goal <InfoTooltip message={`Default value: ${goal}`}/>
                        <div className="input-group">
                            {project === 'Menscoach' || 'MuscleBoosterOld' ? (
                                <Select required
                                        value={{value: goal, label: goal}}
                                        onChange={option => setGoal(option.value)}
                                        options={goalOptions}
                                        className="select-field"
                                />
                            ) : (
                                <Select required
                                        value={goal}
                                        onChange={setGoal}
                                        options={goalOptions}
                                        isMulti
                                        className="select-field"
                                />
                            )}
                        </div>
                    </label>
                )}
                <label>
                    Branch Name<InfoTooltip message="Default value: default"/>
                    <div className="input-group">
                        <input
                            type="text"
                            value={branchId}
                            onChange={e => setBranch(e.target.value)}
                            className="input-field"
                            placeholder="Enter branch ID"
                        />
                        <button type="button" className="clear-button" onClick={() => setBranch('')}>×</button>
                    </div>
                </label>
                <label>
                    Upsell Product Code<InfoTooltip message="If you want to buy an upsell product enter productCode"/>
                    <div className="input-group">
                        <input
                            type="text"
                            value={upsellProductCode}
                            onChange={e => setUpsellProductCode(e.target.value)}
                            className="input-field"
                            placeholder="Enter upsell productCode"
                        />
                        <button type="button" className="clear-button" onClick={() => setUpsellProductCode('')}>×</button>
                    </div>
                </label>
                <label>
                    User Weight<InfoTooltip message={`Default value: ${formOptions.userWeight}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userWeight}
                            onChange={e => setUserWeight(e.target.value)}
                            onBlur={calculateBmi}
                            className="input-field"
                            placeholder="Enter user's weight"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserWeight('')}>×</button>
                    </div>
                </label>
                <label>
                    User Height<InfoTooltip message={`Default value: ${formOptions.userHeight}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userHeight}
                            onChange={e => setUserHeight(e.target.value)}
                            onBlur={calculateBmi}
                            className="input-field"
                            placeholder="Enter user's height"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserHeight('')}>×</button>
                    </div>
                </label>
                <label>
                    User BMI: {bmi ? `${bmi}` : 'N/A'}<br/>
                </label>
                <label>
                    User Age<InfoTooltip message={`Default value: ${formOptions.userAge}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userAge}
                            onChange={e => setUserAge(e.target.value)}
                            className="input-field"
                            placeholder="Enter user's age"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserAge('')}>×</button>
                    </div>
                </label>
                <label>
                    User Target Weight<InfoTooltip message={`Default value: ${formOptions.userWeightTarget}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userWeightTarget}
                            onChange={e => setUserWeightTarget(e.target.value)}
                            className="input-field"
                            placeholder="Enter user's target weight"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserWeightTarget('')}>×
                        </button>
                    </div>
                </label>
                <label>
                    Fitness Level <InfoTooltip message={`Default value: ${formOptions.fitlevels[0]}`}/>
                    <div className="input-group">
                        <select value={fitLvl} onChange={e => setFitLvl(e.target.value)} className="select-field">
                            {formOptions.fitlevels.map(f => (
                                <option key={f} value={f}>{f}</option>
                            ))}
                        </select>
                    </div>
                </label>
                {selectedProject.problem_zones.length > 0 && (
                    <label>
                        Problem Zones <InfoTooltip message="Select multiple problem zones"/>
                        <div className="input-group">
                            <Select
                                value={problemZones}
                                onChange={setProblemZones}
                                options={problemZoneOptions}
                                isMulti
                                className="select-field"
                            />
                        </div>
                    </label>
                )}
                {selectedProject.training_locations && selectedProject.training_locations.length > 0 && (
                    <label>
                        Training Locations <InfoTooltip message="Select training locations"/>
                        <div className="input-group">
                            <Select
                                value={trainingLocations}
                                onChange={setTrainingLocations}
                                options={trainingLocationsOptions}
                                isMulti
                                className="select-field"
                            />
                        </div>
                    </label>
                )}
                {selectedProject.equipment_preset.length > 0 && (
                    <label>
                        Equipment Preset <InfoTooltip message="Select an equipment preset"/>
                        <div className="input-group">
                            <Select
                                value={{value: equipmentPreset, label: equipmentPreset}}
                                onChange={option => setEquipmentPreset(option.value)}
                                options={equipmentPresetOptions}
                                className="select-field"
                            />
                        </div>
                    </label>
                )}
                <label>
                    Country <InfoTooltip message="Select your country"/>
                    <div className="input-group">
                        <Select
                            value={country}
                            onChange={setCountry}
                            options={countries}
                            className="select-field"
                        />
                    </div>
                </label>
                <label>
                    Consent Email<InfoTooltip message="Consent to receive emails"/>
                    <div className="input-group">
                        <input
                            type="checkbox"
                            checked={consentEmail}
                            onChange={e => setConsentEmail(e.target.checked)}
                            className="checkbox-field"
                        />
                    </div>
                </label>
                <button type="submit" className="button">Trigger Job</button>
            </form>
            {showFlash && (
                <div className={`flash-message ${isPositiveMessage ? 'positive' : 'negative'}`}
                     onClick={handleFlashMessageClick}>
                    {flashMessage}
                </div>
            )}
        </div>
    );
}

export default PaidUsers;
